import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { SSOLogin } from '../views/sso-login';
import { Dashboard } from '../views/dashboard';
import { CreateEmployeeAccount } from '../views/create-employee-account';
import { SetPassword } from '../views/set-password';
import { Organizations } from '../views/organizations';
import { UniversalAuth } from '../views/universal-auth';
import { InternalAuth } from '../views/internal-auth';

const router = createBrowserRouter([
  {
    path: '/sso/SingleSignOnService/:appId',
    element: <SSOLogin/>
  },
  {
    path: '/sp/:token',
    element: <SetPassword/>
  },
  {
    path: '/create-employee-account',
    element: <CreateEmployeeAccount/>
  },
  {
    path: '/organizations',
    element: <Organizations/>
  },
  {
    path: '/universal-auth/:uuid',
    element: <UniversalAuth/>
  },
  {
    path: '/ia/:domain',
    element: <InternalAuth/>
  },
  {
    path: '*',
    element: <Dashboard/>
  }

]);

export const Router = React.memo(function Router (): JSX.Element {
  return (
    <RouterProvider router={router}/>
  );
});
